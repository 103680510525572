import request from "../request";

export async function create({ email, password, ms_token }) {
  const query = {
    email,
    password,
    remember: true,
    ms_token
  };

  try {
    query.cpu_cores = navigator?.hardwareConcurrency;
    query.screen_width = Math.round(
      (window?.screen?.width || 0) * (window?.devicePixelRatio || 0)
    );
    query.screen_height = Math.round(
      (window?.screen?.height || 0) * (window?.devicePixelRatio || 0)
    );

    const canvas = document.createElement("canvas");
    const webgl = canvas.getContext("webgl");
    const renderer = webgl.getExtension("WEBGL_debug_renderer_info");

    query.gpu_vendor = webgl.getParameter(renderer.UNMASKED_VENDOR_WEBGL);
    query.gpu_renderer = webgl.getParameter(renderer.UNMASKED_RENDERER_WEBGL);
  } catch (_) {
    // Ignore analytics errors.
  }

  const response = await request.post("session", { body: query });

  return response.body;
}
