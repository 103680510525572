import qs from "qs";
import urlJoin from "url-join";

import { ERRORS_LOCALES } from "sf/i18n";

import config from "src/config";
import { getSessionId, getUserLocale } from "src/modules/Auth/context";

const HTTP_METHODS = ["get", "post", "put", "patch", "head", "delete"];

const request = HTTP_METHODS.reduce((reqMethods, method) => {
  reqMethods[method] = (path, options = {}) =>
    requestFn(path, { ...options, method: method.toUpperCase() });
  return reqMethods;
}, {});

window.apiRequest = request;
export default request;

function serializeQueryParams(query) {
  const serializedQuery = { ...query };

  if (Array.isArray(serializedQuery.$order)) {
    const [by, direction] = serializedQuery.$order;
    serializedQuery.$order = `[["${by}","${direction.toUpperCase()}"]]`;
  }

  ["$where", "$attributes"].forEach((key) => {
    if (serializedQuery[key]) {
      serializedQuery[key] = JSON.stringify(serializedQuery[key]);
    }
  });

  return qs.stringify(serializedQuery);
}

async function parseResponseBody(res) {
  const contentType = res.headers.get("Content-Type")?.toLowerCase() || "";
  if (contentType.includes("application/json")) {
    return await res.json();
  }
  return await res.text();
}

function handleCustomError(res, localeErrors) {
  const errorCode = res.body?.error?.errorCode;
  const errorMessage =
    res.body?.error?.message || localeErrors[errorCode] || localeErrors[4001];
  const error = new Error(errorMessage);
  Object.assign(error, res);
  return error;
}

async function requestFn(path, options = {}) {
  const session = getSessionId();
  const localeErrors = getUserLocale()[ERRORS_LOCALES];

  options = {
    ...options,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      session,
      ...options.headers,
    },
  };

  if (
    options.body &&
    typeof options.body !== "string" &&
    options.headers["Content-Type"] === "application/json"
  ) {
    options.body = JSON.stringify(options.body);
  }

  path = /^https?/.test(path) ? path : urlJoin(config.api.base, path);

  if (options.query) {
    path = `${path}?${serializeQueryParams(options.query)}`;
  }

  try {
    const res = await fetch(path, options);
    const responseBody = await parseResponseBody(res);
    const response = Object.assign({}, res, { body: responseBody });

    if (!res.ok) {
      throw handleCustomError(response, localeErrors);
    }

    return response;
  } catch (e) {
    throw handleCustomError({}, localeErrors);
  }
}
